var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "road-wrap" },
    _vm._l(_vm.roadData, function (item, ind) {
      return _c("div", { key: ind, staticClass: "item-content" }, [
        _c("span", { staticClass: "name" }, [
          _vm._v(_vm._s(item.equipmentTypeName)),
        ]),
        _c("span", { staticClass: "count" }, [
          _vm._v(_vm._s(item.totalEquipmentNumber)),
        ]),
        _c("div", { staticClass: "line-wrap" }, [
          _c("span", { staticClass: "online-num" }, [
            _c("span", [_vm._v(_vm._s(item.onlineEquipmentNumber))]),
          ]),
          _c("span", { staticClass: "offline-num" }, [
            _c("span", [_vm._v(_vm._s(item.offlineEquipmentNumber))]),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }